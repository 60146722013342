<template>
  <div class="warp">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="onLoad">
        <div class="item-list" v-for="(item, index) in itemList" :key="index">
          <div class="left">
            <p class="p1">{{item.balanceName}}</p>
            <p>时间：{{conversionTime(item.createTime)}}</p>
            <p v-if="item.balanceFlag == 2">收运订单号：{{item.orderNo}}</p>
          </div>
          <div class="right" v-if="item.balanceFlag == 1">+ {{item.balanceMoney}}</div>
          <div class="right" v-if="item.balanceFlag == 2">- {{item.balanceMoney}}</div>
        </div>
      </van-list>
    </van-pull-refresh>
    <div class="btn">
      <van-button round block type="info" @click="gobackClick">返回</van-button>
    </div>
  </div>
</template>

<script>
import { balancelist } from '@/api/order.js'
export default {
  data () {
    return {
      loading: false, // 上拉加载状态
      finished: false, // 是否已加载完成，加载完成后不再触发load事件进去多次加载请求
      refreshing: false, // 下拉刷新状态
      total: undefined,
      itemList: [],
      query: {
        pageNum: 1,
        pageSize: 10
      }
    }
  },
  created() {
    this.balancelist()
  },
  methods: {
    balancelist() { // 流水明细
      balancelist(this.query).then(res => {
        if(res.data.code === 200) {
          if (this.itemList.length) {
            if (this.loading) { // 上拉加载
              if (res.data.rows.length < 10) { // 没有更多数据
                this.finished = true // 表示上拉加载完毕
              }
              this.itemList = this.itemList.concat(res.data.rows) // 上拉加载新数据添加到数组中
              this.$nextTick(() => { // 在下次 DOM 更新循环结束之后执行延迟回调
                this.loading = false // 关闭上拉加载中
              })
            }
            if (this.refreshing) { // 关闭下拉刷新
              this.refreshing = false // 关闭下拉刷新
              this.itemList = res.data.rows // 重新给数据赋值
            }
          } else {
            this.itemList = res.data.rows
          }
          this.total = res.data.total
        }
      })
    },
    onLoad() { // 上拉加载
      setTimeout(() => {
        if (this.itemList.length < this.total) {
          this.loading = true
          this.query.pageNum++
          this.balancelist()
        } else {
          this.finished = true
        }
      }, 300)
    },
    onRefresh() { // 下拉刷新
      setTimeout(() => {
        // 下拉刷新后，可以重新触发上拉加载
        this.finished = false;
        // 将 refreshing 设置为 true，表示处于刷新状态
        this.refreshing = true
        this.query.pageNum = 1
        this.balancelist()
      }, 300)
    },
    gobackClick() {
      this.$router.push({path: 'Profileuser'})
    }
  }
}

</script>
<style lang="scss" scoped>
.warp {
  padding-bottom: 105px;
  .item-list {
    display: flex;
    justify-content: space-between;
    padding: 7px 15px;
    background-color: #fff;
    margin-bottom: 10px;
    .left {
      p {
        color: #999;
        font-size: 13px;
        line-height: 23px;
      }
      .p1 {
        font-size: 16px;
        font-weight: 600;
        color: #333;
      }
    }
    .right {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
.btn {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
}
::v-deep .van-button--round {
  border-radius: 3px;
}
::v-deep .van-button--info {
  background-color: #0087f0;
  border: 1px solid #0087f0;
}
::v-deep .van-tab__pane, .van-tab__pane-wrapper {
  height: 100%;
}
::v-deep .van-pull-refresh {
  height: 100%;
  overflow: scroll;
}
</style>
